import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';

const TileLite = ({
  bodyAlign = TileLite.bodyAlignLeft,
  children,
  title,
  conditional = true,
}) => {
  const theme = useContext(ThemeContext);

  // enable tile to be 'turned off' as required
  if (conditional === false) {
    return <>{children}</>;
  }

  return (
    <>
      <div className="lite-wrapper">
        <h1 className="lite-title">{title}</h1>
        <div className="lite-body">{children}</div>
      </div>
      <style jsx>{`
        .lite-wrapper {
          margin-bottom: 30px;
        }
        .lite-title {
          position: relative;
          border-bottom: 1px solid ${theme.colors.primary};
          color: ${theme.commoncolors.greycharcoal};
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 0;
          padding: 15px;
        }
        .lite-body {
          padding: 15px 0 15px 0;
          text-align: ${bodyAlign};
        }
        @media only screen and (min-width: ${theme.breakpoints.lgUp}) {
          .lite-title {
            font-size: 20px;
            line-height: 25px;
          }
        }
      `}</style>
    </>
  );
};

TileLite.bodyAlignLeft = 'left';
TileLite.bodyAlignCenter = 'center';

TileLite.propTypes = {
  children: PropTypes.any,
  conditional: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TileLite;
