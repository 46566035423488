import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import Cookies from 'react-cookies';
import { useSelector } from 'react-redux';
import Spinner from '../components/_generic/spinner/Spinner';
import Tile from '../components/_generic/tile/Tile';
import TileBody from '../components/_generic/tile/TileBody';
import TileLite from '../components/_generic/tile/TileLite';
import TileLiteTitle from '../components/_generic/tile/TileLiteTitle';
import { panel } from '../components/checkout/details/UserPanelSetup';
import LoginForm from '../components/login/LoginForm';
import LoginTitle from '../components/login/LoginTitle';
import { PAGE_TYPES } from '../config/constants/page-types';
import COOKIES from '../config/cookies/cookies';
import { REGISTER_URL } from '../config/links/links';
import { BRAND_WOWCHER } from '../config/setup/setup';
import {
  LOGIN,
  LOGIN_REGISTER,
  TITLE_LOGIN,
  LOGIN_REGISTER_LINK,
  EXTEND_DROP_DOWN_TITLE,
  EXTEND_SUB_TITLE,
  EXTEND_LOGIN_MESSAGE,
} from '../config/text/text';
import { trackSecurePage } from '../helpers/analytics';
import { redirectToURL } from '../helpers/url';
import LoginLayout from '../layouts/LoginLayout';
import { getServerSideEssentials } from '../redux/actions/ssr';
import { LOADING_STATUS } from '../redux/reducers/loading';
import { wrapper } from '../redux/store/store';

const Login = ({ referrerPath }) => {
  const router = useRouter();
  const [activePanel, setActivePanel] = useState(panel.login);
  const [sourcePageType, setSourcePageType] = useState('');
  const [status] = useSelector((state) => [state.profile.status]);
  const sourcePageTypeCookie = Cookies.load(COOKIES.loginSourcePageType);

  useEffect(() => {
    trackSecurePage({
      pageName: 'my account: Login',
      section1: 'my account',
      section2: 'my account: Login',
    });
  }, []);

  useEffect(() => {
    if (sourcePageTypeCookie) {
      Cookies.remove(COOKIES.loginSourcePageType, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        path: '/',
      });
      setSourcePageType(sourcePageTypeCookie);
    }
  }, [sourcePageTypeCookie]);

  if (activePanel === panel.registration) {
    redirectToURL(`/${REGISTER_URL}`, router);
  }

  const tileTitle = (
    <LoginTitle
      rightLink={LOGIN_REGISTER_LINK}
      rightText={LOGIN_REGISTER}
      title={LOGIN}
      urlPath={REGISTER_URL}
    />
  );

  return (
    <>
      <div className="position-relative">
        {sourcePageType === PAGE_TYPES.extendLanding && (
          <TileLite
            bodyAlign={TileLite.bodyAlignCenter}
            title={<TileLiteTitle title={EXTEND_DROP_DOWN_TITLE} />}
          >
            <p>{EXTEND_SUB_TITLE}</p>
            <p>{EXTEND_LOGIN_MESSAGE[process.env.NEXT_PUBLIC_BRAND]}</p>
          </TileLite>
        )}
        {status === LOADING_STATUS.LOADING && <Spinner hasMsg={false} />}
        <Tile title={tileTitle}>
          <TileBody>
            <LoginForm
              isLoginPage
              referrerPath={referrerPath}
              setActivePanel={setActivePanel}
            />
          </TileBody>
        </Tile>
      </div>
    </>
  );
};

Login.title = TITLE_LOGIN[process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER];
Login.layout = LoginLayout;

export const getServerSideProps = wrapper.getServerSideProps(
  async ({ req, store }) => {
    const referrer = req.headers.referer;
    const host = req.headers.host;
    const startIndex = referrer.indexOf(host) + host.length;
    const referrerPath = referrer.slice(Math.max(0, startIndex));

    await store.dispatch(getServerSideEssentials(req.url)).catch((error) => {
      console.log(`getServerSideEssentials error: ${error}`);
    });

    return {
      props: { referrerPath },
    };
  },
);

export default Login;
