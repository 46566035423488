import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { redirectToURL } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import { setUserEmail } from '../../redux/actions/email';

const LoginTitle = ({ title, rightText, rightLink, urlPath }) => {
  const theme = useContext(ThemeContext);
  const router = useRouter();
  const dispatch = useDispatch();

  const linkAction = async (event) => {
    event.preventDefault();
    dispatch(setUserEmail(''));
    redirectToURL(`/${urlPath}`, router);
  };

  return (
    <>
      <div className="row details-title align-items-center">
        <div className="col">{title}</div>
        <div className="col text-right details-title__right-block">
          <em>
            {rightText} &nbsp;
            <Link href="#">
              <a
                onClick={linkAction}
                onKeyDown={linkAction}
                role="link"
                tabIndex={0}
              >
                {rightLink}
              </a>
            </Link>
          </em>
        </div>
      </div>
      <style jsx>{`
        .details-title {
          text-transform: ${theme.text.logintitle.transform};
        }
        .details-title__right-block a {
          color: ${theme.colors.primary};
        }
        .details-title__right-block em {
          font-family: sans-serif;
          font-size: 14px;
          font-weight: 400;
        }
        .details-title__right-block em a {
          font-weight: 700;
          font-size: 110%;
        }
      `}</style>
    </>
  );
};

LoginTitle.propTypes = {
  rightLink: PropTypes.string,
  rightText: PropTypes.string,
  title: PropTypes.string,
  urlPath: PropTypes.string,
};

LoginTitle.defaultProps = {
  rightLink: '',
  rightText: '',
  title: '',
  urlPath: '',
};

export default LoginTitle;
