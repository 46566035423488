import PropTypes from 'prop-types';
import React from 'react';
import Input from './Input';

const FormikInput = ({
  inputError = '',
  inputValue = '',
  inputTouched = false,
  prePopulated = false,
  ...props
}) => (
  <Input
    bottomLined={Boolean(inputValue && !inputError && !prePopulated)}
    error={
      Boolean(inputValue && inputError) || Boolean(inputTouched && inputError)
    }
    inputValue={inputValue}
    normal={Boolean(!inputError && !inputValue)}
    valid={Boolean(inputValue && !inputError)}
    {...props}
  />
);

FormikInput.propTypes = {
  inputError: PropTypes.string,
  inputTouched: PropTypes.bool,
  inputValue: PropTypes.string,
  prePopulated: PropTypes.bool,
};

export default FormikInput;
