import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../providers/ThemeProvider';

const PrivacyText = ({ text }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <p className="privacy-policy text-justify">
        <small>
          <span
            className="privacy-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </small>
      </p>
      <style jsx>{`
        .privacy-policy {
          padding-top: 15px;
        }
      `}</style>
      <style global jsx>{`
        .privacy-policy a {
          color: ${theme.colors.primary};
        }
        .privacy-text {
          padding-left: 0px;
        }
        .privacy-policy a:hover {
          color: ${theme.colors.primary};
          text-decoration: underlined;
        }
        .privacy-policy div {
          color: ${theme.colors.primary};
          cursor: pointer;
          display: inline;
        }
        .privacy-policy div:hover {
          color: ${theme.colors.primary};
          text-decoration: underline;
          cursor: pointer;
          display: inline;
        }
      `}</style>
    </>
  );
};

PrivacyText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PrivacyText;
