import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';

const Input = ({
  onChangeFunction,
  onBlurFunction,
  onFocusFunction,
  name,
  type,
  showValidIconOnly = false,
  icon,
  placeholder,
  valid,
  error,
  normal,
  errorText,
  required,
  inputValue,
  extraClasses,
  accessable,
  disable,
  bottomLined,
  hint,
  min,
  max,
  maxLength,
  dataqa,
  isCheckout = false,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const componentClass = classNames(`input-container ${extraClasses}`, {
    error,
    normal,
    'underlined-input': bottomLined,
    valid,
  });
  const showError = error && errorText;

  return (
    <>
      <div className={componentClass}>
        <div className="form-group">
          <input
            {...props}
            className={`form-control field__${name}`}
            data-qa={dataqa}
            name={name}
            onBlur={(event) => onBlurFunction(event)}
            onChange={(event) => onChangeFunction(event)}
            onFocus={(event) => onFocusFunction(event)}
            placeholder={placeholder}
            required={required}
            type={type}
            value={inputValue}
            {...(disable && { disabled: 'disabled' })}
            {...(accessable && { 'aria-label': `${placeholder}` })}
            max={max}
            maxLength={maxLength}
            min={min}
          />
          {((icon && !showValidIconOnly) ||
            (icon && showValidIconOnly && valid)) && (
            <span className="input-container__icon">
              <FontAwesomeIcon icon={icon} style={{ height: 16, width: 16 }} />
            </span>
          )}
          {hint && <span className="input-container__hint">{hint}</span>}
        </div>
        {Boolean(showError) && (
          <div className="bg-danger">
            <span>{errorText}</span>
          </div>
        )}
      </div>
      <style jsx>{`
        .form-control {
          border-radius: ${isCheckout ? '5px' : '0px'};
        }
        .input-container__icon {
          color: ${theme.colors.inputiconcolor};
        }
        .form-control:focus {
          border-color: ${theme.colors.inputfocuscolor};
        }
        .underlined-input .form-control:focus {
          border-color: ${theme.colors.inputlogincolor};
        }
        .underlined-input.valid .form-control {
          border-bottom-color: ${theme.colors.inputlogincolor};
        }
        .underlined-input.valid .input-container__icon {
          color: ${theme.colors.inputvalidcolor};
        }
        .lowercase_placeholder .form-control::placeholder {
          text-transform: lowercase;
        }
        .error .form-control,
        .error .form-control:focus {
          border-color: ${theme.colors.inputinvalidcolor};
        }
        .error .input-container__icon {
          color: ${theme.colors.inputinvalidcolor};
        }
        .bg-danger {
          background: ${theme.colors.inputinvalidcolor};
          border-radius: ${isCheckout ? '5px' : '0px'};
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .mobile-highlight-border .form-control,
          .mobile-highlight-border.underlined-input .form-control:focus,
          .mobile-highlight-border.underlined-input.valid .form-control {
            border-color: ${theme.colors.primary};
            border-radius: ${isCheckout ? '5px' : '0px'};
          }
        }
      `}</style>
    </>
  );
};

Input.propTypes = {
  accessable: PropTypes.bool,
  bottomLined: PropTypes.bool,
  dataqa: PropTypes.string,
  disable: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  extraClasses: PropTypes.string,
  hint: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  normal: PropTypes.bool,
  onBlurFunction: PropTypes.func,
  onChangeFunction: PropTypes.func.isRequired,
  onFocusFunction: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showValidIconOnly: PropTypes.bool,
  type: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
};

Input.defaultProps = {
  accessable: true,
  bottomLined: false,
  dataqa: null,
  disable: false,
  error: false,
  errorText: '',
  extraClasses: '',
  hint: '',
  icon: '',
  inputValue: '',
  name: '',
  normal: false,
  onBlurFunction: () => {},
  onChangeFunction: () => {},
  onFocusFunction: () => {},
  placeholder: '',
  required: true,
  showValidIconOnly: false,
  type: '',
  valid: false,
  value: '',
};

export default Input;
