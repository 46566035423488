import PropTypes from 'prop-types';
import React from 'react';
import { CLOCK } from '../../../config/constants/images';

const TileLiteTitle = ({ title = '', icon = CLOCK }) => {
  return (
    <>
      <img
        alt=""
        className="extension-icon"
        src={icon[process.env.NEXT_PUBLIC_BRAND]}
      />
      <span className="extension-title">{title}</span>
      <style jsx>{`
        .extension-icon {
          position: absolute;
          top: 10px;
          width: 30px;
        }
        .extension-title {
          margin-left: 40px;
        }
      `}</style>
    </>
  );
};

TileLiteTitle.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
};

export default TileLiteTitle;
